import {
  championshipLeagues,
  UPCOMING_GAMES_LABEL,
  ROUTES,
  TODAYS_GAMES_LABEL,
} from 'constants';
import { CHOSEN_LEAGUE } from 'constants/localStorageKey';
import React, { useMemo, useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { createSelectedValue } from 'helpers';
import { allTeamsResetAction } from 'redux/teams/actionCreators.js';
import { allGamesResetAction } from 'redux/games/actionCreators.js';
import useLocalStorage, { useGetSortedLeaguesList } from 'hooks';

import { Selector } from 'components';

import { SelectContainer, StyledLabel } from './style';

export const ChampionshipHeader = ({ leagues, configuration }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = Object.fromEntries([...searchParams]);

  // eslint-disable-next-line no-unused-vars
  const [savedLeague, setSavedLeague] = useLocalStorage(CHOSEN_LEAGUE, '');

  // Log google analytics events
  const analytics = getAnalytics();

  const allLeagues = useMemo(() => {
    if (leagues.length) {
      return leagues?.sort((a, b) =>
        a.league_name.localeCompare(b.league_name)
      );
    } else {
      return [];
    }
  }, [leagues]);

  const { leaguesList } = useGetSortedLeaguesList(allLeagues || []);

  const handleLeaguesChange = useCallback(
    (selected) => {
      const { value, label } = selected;

      logEvent(analytics, 'select_content', {
        content_type: 'Changed Championship League',
        content_id: label,
      });

      dispatch(allTeamsResetAction());
      dispatch(allGamesResetAction());

      if (label === UPCOMING_GAMES_LABEL) {
        setSavedLeague('');
        setTimeout(() => {
          navigate(`/${ROUTES.UPCOMING_GAMES}`);
        }, 500);
      } else if (championshipLeagues.includes(selected.label)) {
        setSearchParams(
          (prevValue) => {
            prevValue.delete('eventId');
            prevValue.set('league', label);
            prevValue.set('leagueId', value);
            return prevValue;
          },
          { replace: true }
        );
        navigate(0);
      } else if (label === TODAYS_GAMES_LABEL) {
        dispatch(allGamesResetAction());
        setSearchParams([]);
        navigate('/');
      } else {
        navigate(`/?league=${label}&leagueId=${value}`);
      }
    },
    [analytics, dispatch, navigate, setSavedLeague, setSearchParams]
  );

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {configuration.site_name !== 'Pony Time' ? (
        <SelectContainer $isCentered={true}>
          <StyledLabel>
            <Selector
              onChange={handleLeaguesChange}
              options={leaguesList}
              placeholder="Select League..."
              value={createSelectedValue({
                value: params?.leagueId,
                label: params?.league,
              })}
            />
          </StyledLabel>
        </SelectContainer>
      ) : null}
    </>
  );
};
