import moment from 'moment';
import { useGetUserTimeZone } from 'hooks';

const getNowDate = (timeZone) =>
  moment
    .utc()
    .tz(timeZone || 'America/New_York')
    .valueOf();

export default function getIndex(arr, league) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { timeZone: userTimeZone } = useGetUserTimeZone();

  if (!arr) return;
  let _currentGameIndex;

  const params = new URLSearchParams(window.location.search);
  const gameId = params.get('eventId');

  if (gameId) {
    const foundIndex = arr.findIndex(
      (game) => game.event_id === Number(gameId)
    );
    _currentGameIndex = foundIndex;
    return _currentGameIndex;
  }

  const _currentIndex = arr.findIndex((i) =>
    league
      ? moment.utc(i.event_start, 'YYYY-MM-DD HH:mm:ss').valueOf() >
        getNowDate(userTimeZone || i.stadium_time_zone)
      : (moment.utc(i.event_start, 'YYYY-MM-DD HH:mm:ss').valueOf() >
          getNowDate(userTimeZone || i.stadium_time_zone) ||
          getNowDate(userTimeZone || i.stadium_time_zone) <
            moment
              .utc(i.event_start, 'YYYY-MM-DD HH:mm:ss')
              .add(4, 'hours')
              .valueOf()) &&
        getNowDate(userTimeZone || i.stadium_time_zone) <
          moment
            .utc(i.event_start, 'YYYY-MM-DD HH:mm:ss')
            .tz(userTimeZone || i.stadium_time_zone)
            .add(4, 'hours')
            .valueOf()
  );

  const lastGame =
    moment
      .utc(arr[arr.length - 1].event_start, 'YYYY-MM-DD HH:mm:ss')
      .tz(userTimeZone)
      .valueOf() <
    getNowDate(userTimeZone || arr[arr.length - 1].stadium_time_zone);

  const indexOfNotFound = !lastGame ? 0 : arr.length - 1;
  _currentGameIndex = _currentIndex === -1 ? indexOfNotFound : _currentIndex;
  return _currentGameIndex;
}
